import React, { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';

import styles from './document.module.sass';
import { useAppState } from '../../../store/hooks/use-app';

import {
  useBubblesActions,
  useBubblesState,
} from '../../../store/hooks/use-bubbles';
import { ProseMirrorEditor } from './prosemirror-editor';

import { v4 as uuidv4 } from 'uuid';

import schema from './document-schema';

import { useCookies } from 'react-cookie'; 

const getDocFromPlainText = (plainText, id = uuidv4()) => {
  /*const nodes = plainText
    .split('\n')
    .map((line) => {
      const textNode = line ? schema.text(line) : schema.text(' ');
      console.log('trying to create a paragraph for text: ', line);
      const n = schema.node('paragraph', { id: uuidv4() }, [textNode]);
      return n;
    })
    .filter((n) => !!n);
  console.log(nodes);*/
  const node = schema.node('paragraph', { id }, [schema.text(plainText)]);
  return schema.node('doc', null, [node]);
};

const buildDocument = ({ parentId, documentChildren, bubbles }) => {
  // go through document children and their children
  // parse the tree and add them as nodes accordingly
  const emptyNode = schema.node('paragraph', { id: uuidv4() }, []);

  const nodes = documentChildren?.length
    ? documentChildren.map((bubbleId) => {
        const bubble = bubbles.find((b) => b.id === bubbleId);

        const node = bubble?.name
          ? schema.nodeFromJSON(JSON.parse(bubble.name))
          : schema.node('paragraph', { id: bubbleId }, []);

        if (node.type.name === 'list_item') {
          // we must wrap this in a list
          const wrapperNode = schema.node('bullet_list', { id: uuidv4() }, [
            node,
          ]);
          return wrapperNode;
        }

        return node;
      })
    : [emptyNode];

  return schema.node('doc', null, nodes);
};

const Document = ({
  bubble,
  parentCanvasIndex,
  canvasParentId,
  className,
  type = 'full',
  updateCount,
  dimensions,
}) => {
  const { documentChildren, id: parentBubbleId } = bubble;

  const {
    bubbles,
    focusedBubble: { viewMode },
  } = useBubblesState();
  const {
    addDocumentBubble,
    updateDocumentBubble,
    moveDocumentBubble,
    removeDocumentBubbles,
    updateBubble,
  } = useBubblesActions();

  const [initialDoc, setInitialDoc] = useState(() => {
    if (bubble.id) {
      return buildDocument({
        parentId: bubble.id,
        documentChildren,
        bubbles,
      });
    }
  });

  const [cookies, setCookie] = useCookies(['documentWidth']); 
  const [documentWidth, setDocumentWidth] = useState(() => {
    return cookies.documentWidth ? parseInt(cookies.documentWidth, 10) : 320;
  });

  const documentRef = useRef(null);

  const handleResizeMouseDown = (e) => {
    const startX = e.clientX;
    const startWidth = documentWidth;

    const handleMouseMove = (e) => {
      const newWidth = e.clientX;
      if (newWidth < 320) {
        newWidth = 320;
      }  
      setDocumentWidth(newWidth);
      setCookie('documentWidth', newWidth, { path: '/', maxAge: 604800 });
    };

    const handleMouseUp = () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const cn =
    className || classnames(styles.document, styles[`${viewMode}ViewMode`]);

  useEffect(() => {
    if (viewMode === 'document') {
      setDocumentWidth('100%'); 
    } else if (viewMode === 'hybrid') {
      setDocumentWidth(cookies.documentWidth || 320); 
    }
  }, [viewMode]); 

  // document should update when bubble id changes (main doc)
  useEffect(() => {
    if (bubble.id && type === 'full') {
      setInitialDoc(
        buildDocument({
          parentId: bubble.id,
          documentChildren,
          bubbles,
        }),
      );
    }
  }, [bubble.id]);



  useEffect(() => {
    if (updateCount) {
      setInitialDoc(
        buildDocument({
          parentId: bubble.id,
          documentChildren,
          bubbles,
        }),
      );
    }
  }, [updateCount]);


  // update text content when generating from API
  useEffect(() => {
    if (bubble.fromHtml) {
      try {
        const [firstDocChildId] = documentChildren;
        const doc = getDocFromPlainText(bubble.fromHtml, firstDocChildId);
        // const doc = DOMParser.fromSchema(schema).parse(bubble.fromHtml);
        console.log(doc);
        setInitialDoc(doc);
        // reset fromHtml after it has been processed
        updateBubble({
          id: bubble.id,
          properties: { fromHtml: '' },
        });
      } catch (e) {
        console.error(e);
      }
    }
  }, [bubble.fromHtml]);

  const onDocumentChange = (editorState) => {
    const { doc } = editorState;

    // TODO: extend this to child nodes, now only checking root node ghosts
    const existingNodes = [];
    // create new
    doc.descendants((node, pos, parent, index) => {
      const {
        attrs: { id: nodeId },
      } = node;
      const {
        attrs: { id: parentId },
      } = parent || {};

      if (node.isLeaf) {
        return;
      }

      if (nodeId) {
        const bubbleExists = !!bubbles.find((b) => b.id === nodeId);

        if (!parentId) {
          existingNodes.push(nodeId);
        }

        if (!bubbleExists) {
          // create new
          const parentExists = !!bubbles.find((b) => b.id === parentId);

          if (parentId && !parentExists) {
            return;
          }

          const { node: previousNode } = doc.childBefore(pos);
          const previousSibling = previousNode?.attrs?.id;

          addDocumentBubble({
            id: nodeId,
            parentBubbleId: parentId || parentBubbleId,
            previousSibling,
            properties: { name: JSON.stringify(node.toJSON()) },
            canvasParentId,
            parentCanvasIndex,
          });
        } else {
          // update existing

          // check whether its location within the parent document has changed
          const _parentBubbleId = parentId || parentBubbleId;
          const parentBubble = bubbles.find((b) => b.id === _parentBubbleId);
          if (parentBubble) {
            const oldIndex = parentBubble.documentChildren.findIndex(
              (bId) => bId === nodeId,
            );
            if (oldIndex !== index && oldIndex >= 0) {
              /*console.log(
                'Index within parent document has changed! We should update it.',
              );
              console.log(
                `Current index in document ${oldIndex} vs. index ${index} for node id ${nodeId}`,
              );*/
              moveDocumentBubble({
                id: nodeId,
                newIndex: index,
                oldIndex,
                canvasParentId,
                parentCanvasIndex,
                parentBubbleId: parentBubble.id,
              });
            }
          }

          const updateName = JSON.stringify(node.toJSON());
          const { name: currentName } = bubbles.find((b) => b.id === nodeId);
          if (updateName !== currentName) {
            updateDocumentBubble({
              id: nodeId,
              canvasParentId,
              parentCanvasIndex,
              properties: { name: JSON.stringify(node.toJSON()) },
            });
          }
        }
      } else {
        // TODO: we need to check that the bubble is really part of the document
        console.error('Failed to add document bubble - missing id.');
        console.log(node);
      }
    });

    // find ghost nodes and remove them
    const ghostNodes = documentChildren.filter(
      (id) => !existingNodes.includes(id),
    );

    if (ghostNodes?.length) {
      removeDocumentBubbles({
        parentBubbleId,
        ids: ghostNodes,
      });
    }

  };


  const captureCopyPaste = (e) => {
    e.stopPropagation();
  };

  const dynamicStyles = {
    minWidth: 'inherit',
    maxWidth: viewMode === 'document' ?  '100%' : documentWidth, 
  };

  return (
    <div
      className={cn}
      id="document"
      onKeyDown={captureCopyPaste}
      style={type === 'full' ? dynamicStyles : {}}
    >
      {bubble.imageContent && <img src={bubble.imageContent} />}
      <div className={`textContent ${styles.textContent}`}>
        {initialDoc ? (
          <ProseMirrorEditor
            initialDoc={initialDoc}
            onChange={onDocumentChange}
          />
        ) : null}
      </div>

      {type === 'full' ? (
        <div
          className={styles.resizeHandle}
          onMouseDown={handleResizeMouseDown}
        />
      ) : null}
    </div>
  );
};

export const InlineDocument = ({
  bubble,
  parentCanvasIndex,
  className,
  updateCount,
  canvasParentId,
  dimensions,
}) => (
  <Document
    dimensions={dimensions}
    updateCount={updateCount}
    bubble={bubble}
    parentCanvasIndex={parentCanvasIndex}
    canvasParentId={canvasParentId}
    className={className}
    type="inline"
  />
);

export default Document;

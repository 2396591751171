import { Schema } from 'prosemirror-model';
import { schema, nodes } from 'prosemirror-schema-basic';

/*const baseNodes = {
  text: {},
  doc: { content: 'text*' },
};

// Mix the nodes from prosemirror-schema-list into the basic schema to
// create a schema with list support.
const mySchema = new Schema({
  nodes: baseNodes,
  marks: schema.spec.marks,
});*/
const baseSchema = new Schema({
  nodes: {
    ...nodes,
    text: {
      group: 'inline',
    },
    list_item: {
      content: 'inline*',
      toDOM() {
        return ['li', 0];
      },
    },
    ordered_list: {
      content: 'inline*',
      toDOM() {
        return ['ol', 0];
      },
    },
    bullet_list: {
      content: 'inline*',
      toDOM() {
        return ['ul', 0];
      },
    },
    paragraph: {
      group: 'block',
      content: 'inline*',
      toDOM() {
        return ['p', 0];
      },
      parseDOM: [
        {
          tag: 'p',
        },
      ],
    },
  },
  marks: schema.spec.marks, // TODO: Requires extension via .append({...}) to cover `<span>` tag and attributes
  topNode: 'paragraph',
});

export default baseSchema;

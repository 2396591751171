import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../api';

const initialState = {
  user: {
    name: '',
  },
  loggedIn: false,
  loadingUser: false,
  darkMode: false,
};

export const loadUserData = createAsyncThunk('app/loadUserData', async () => {
  const user = api.loadUserData();
  return user;
});

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setDarkMode: (state, { payload: { darkMode } }) => {
      state.darkMode = darkMode;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(loadUserData.fulfilled, (state, { payload }) => {
        state.loadingUser = false;
        state.loggedIn = true;
        state.user = payload;
      })
      .addCase(loadUserData.rejected, (state, { error }) => {
        state.loadingUser = false;
        console.error('Error loading user data:', error);
      })
      .addCase(loadUserData.pending, (state) => {
        state.loadingUser = true;
        state.user = initialState.user;
        state.loggedIn = false;
      }),
});

export const actions = appSlice.actions;

export default appSlice.reducer;

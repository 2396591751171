import React from 'react';
import { useBubblesState } from '../../../store/hooks/use-bubbles';
import styles from '../main-view.module.sass';
import BreadcrumbItem from './breadcrumb-item';

const Breadcrumb = () => {
  const state = useBubblesState();
  const { bubbles, focusedBubble } = state;

  const generatePath = (state, targetBubble) => {
    const { focusedBubble, bubbles } = state;
    const path = []; // id array

    function addParentToPath(bubbleId) {
      const parentBubble = bubbles.find((bubble) => {
        return (
          bubble.documentChildren.includes(bubbleId) ||
          bubble.canvasChildren.some((child) => child.id === bubbleId)
        );
      });
      if (parentBubble) {
        path.unshift(parentBubble.id);
        addParentToPath(parentBubble.id);
      }
    }
    addParentToPath(targetBubble?.id ?? focusedBubble.id);
    return path;
  };

  const path = generatePath(state, focusedBubble.id);

  const breadcrumbItems = path?.map((id) => {
    return bubbles.find((bubble) => bubble.id === id);
  });

  if (breadcrumbItems?.length === 0) return <div></div>;
  else
    return (
      <div className={styles.path}>
        {breadcrumbItems.map((item, index) => {
          return (
            <BreadcrumbItem
              key={index}
              item={item}
              nextItem={
                index === breadcrumbItems.length - 1
                  ? focusedBubble
                  : breadcrumbItems[index + 1]
              }
            />
          );
        })}
      </div>
    );
};

export default Breadcrumb;

import React, { useEffect, useState } from 'react';

import { EditorState } from 'prosemirror-state';
import { ProseMirror } from '@nytimes/react-prosemirror';
import { DOMParser } from 'prosemirror-model';

import { exampleSetup } from 'prosemirror-example-setup';
import schema from './name-schema';

import styles from './name-editor.module.sass';

export function NameEditor({ bubbleId, initialName, onNameChange, updateCount }) {
  // It's important that mount is stored as state,
  // rather than a ref, so that the ProseMirror component
  // is re-rendered when it's set
  const plugins = [...exampleSetup({ schema, menuBar: false })];

  const doc = initialName
    ? schema.nodeFromJSON(JSON.parse(initialName))
    : DOMParser.fromSchema(schema).parse('<p></p>');

  const [mount, setMount] = useState(null);
  const [editorState, setEditorState] = useState(
    EditorState.create({
      doc,
      plugins,
    }),
  );

  const onStateChange = (newState) => {
    try {
      onNameChange(JSON.stringify(newState.doc.toJSON()));
    } catch {}
  };

  useEffect(() => {
    const doc = initialName
      ? schema.nodeFromJSON(JSON.parse(initialName))
      : DOMParser.fromSchema(schema).parse('<p></p>');

    setEditorState(
      EditorState.create({
        doc,
        plugins,
      }),
    );
  }, [bubbleId, updateCount]);

  return (
    <div className={`${styles.editor} name-editor`}>
      <ProseMirror
        mount={mount}
        state={editorState}
        dispatchTransaction={(tr) => {
          setEditorState((s) => {
            const newState = s.apply(tr);
            onStateChange(newState);
            return newState;
          });
        }}
      >
        <div ref={setMount} />
      </ProseMirror>
    </div>
  );
}

export default NameEditor;

import classnames from 'classnames';
import React, { useEffect } from 'react';

import styles from './App.module.sass';
import useSync from './hooks/use-sync';

import Header from './components/header/header';
import MainView from './components/main-view/main-view';

import Login from './components/auth/login';
import { useAppActions, useAppState } from './store/hooks/use-app';
import { useBubblesActions, useBubblesState } from './store/hooks/use-bubbles';

const App = () => {
  const { loadUserData } = useAppActions();
  const { loadingUser, loggedIn } = useAppState();
  const { loading } = useBubblesState();
  const { setLoading } = useBubblesActions();

  useEffect(() => {
    setLoading(false);
  }, []);

  useEffect(() => {
    loadUserData();
  }, []);

  // initialize sync handler
  useSync();

  if (loadingUser) {
    return <div>Loading...</div>;
  }

  if (!loggedIn) {
    return <Login />;
  }

  return (
    <div className={classnames(styles.app, { [styles.loading]: loading })}>
      <Header />
      <MainView />
    </div>
  );
};

export default App;

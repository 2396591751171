import React, { Fragment } from 'react';
import {
  useBubblesActions,
  useBubblesState,
} from '../../../store/hooks/use-bubbles';
import styles from '../main-view.module.sass';
import BreadcrumbDropdownMenu from './dropdown-menu';
import { Play } from "lucide-react";


const BreadcrumbItem = ({ item, nextItem }) => {
  const state = useBubblesState();
  const [showDropdown, setShowDropdown] = React.useState(false);
  const { loadBubbles, syncBubbles } = useBubblesActions();

  function findOtherParents(item, nextItem) {
    const nextBubbleId = nextItem.id;
    return state.bubbles.filter((bubble) => {
      return (
        (bubble.documentChildren.includes(nextBubbleId) ||
          bubble.canvasChildren.some((child) => child.id === nextBubbleId)) &&
        bubble.id !== item.id
      );
    });
  }

  const onDropdownItemClick = (selectedItem) => {
    if (!selectedItem) return;
    else {
      syncBubbles();
      loadBubbles({ newFocusedBubbleId: selectedItem.id });
    }
  };

  const renderContent = (renderItem) => {
    const getBubbleContent = (renderItem) => {
      if (renderItem?.name.length === 0) return;
      // First child, can there be multiple?
      const parsed = JSON.parse(renderItem?.name);
      return parsed.content[0];
    };

    const content = getBubbleContent(renderItem);
    switch (content?.type) {
      case 'text':
        return content.text;
      default:
        return 'Untitled';
    }
  };

  const otherParents = findOtherParents(item, nextItem);
  const isDropdownRoot = otherParents.length > 0;

  return (
    <Fragment>
      <div
        onClick={isDropdownRoot ? undefined : () => onDropdownItemClick(item)}
        onMouseEnter={isDropdownRoot ? () => setShowDropdown(true) : undefined}
        onMouseLeave={isDropdownRoot ? () => setShowDropdown(false) : undefined}
      >
        <div className={styles.breadcrumbTitle}>
          {isDropdownRoot ? 'Multiple parents' : renderContent(item)}
        </div>

        {isDropdownRoot && showDropdown && (
          <BreadcrumbDropdownMenu
            items={[item, ...otherParents]}
            onSelect={onDropdownItemClick}
            getBubbleContent={renderContent}
          />
        )}
      </div>

      <div className={styles.breadcrumbSeparator}>▶</div>
    </Fragment>
  );
};

export default BreadcrumbItem;

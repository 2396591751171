import React, { useEffect, useRef, useState } from 'react';
import { Terminal } from "lucide-react";


import { getDocumentTextContent } from '../../components/main-view/document/prosemirror-utils';

import {
  useBubblesActions,
  useBubblesState,
} from '../../store/hooks/use-bubbles';

import styles from './prompt.module.sass';

const PromptDialog = () => {
  const { focusedBubble, bubbles, selectedBubbles, latestPrompts, loadingLatestPrompts } = useBubblesState();
  const { handleTextGeneration, savePrompt, fetchLatestPrompts } = useBubblesActions();

  const [open, setOpen] = useState(false);
  const dialogRef = useRef();
  const togglePromptDialog = () => setOpen((o) => !o);
  const closeDialog = () => setOpen(false);

  const [promptText, setPromptText] = useState('');
  const [savePromptChecked, setSavePrompt] = useState(false);
  const [shouldUpdatePrompts, setShouldUpdatePrompts] = useState(false);

  const promptMultiple = (e) => {
    e.preventDefault();
    const content = getSelectedContent();
    handleTextGeneration({ promptText, content });
    if (savePromptChecked) {
      savePrompt({ promptText });
      setShouldUpdatePrompts(true);
    }
    closeDialog();
  };

  const getSelectedContent = () => {
    const getContentAndChildren = (currentBubble) => {
      const children = currentBubble?.expanded
        ? currentBubble?.canvasChildren
            .map(({ id }) => bubbles.find((b) => b.id === id))
            .map(getContentAndChildren)
        : null;

      return {
        text: getDocumentTextContent({
          documentChildren: currentBubble.documentChildren,
          bubbles,
        }),
        children,
      };
    };

    // find root bubbles in canvas
    const parents = selectedBubbles.map((i) => {
      const { id } = focusedBubble.canvasChildren[i];
      return bubbles.find((b) => b.id === id);
    });

    if (parents?.length) {
      return parents.map(getContentAndChildren);
    }
    return null;
  };

  useEffect(() => {
    if (latestPrompts?.length === 0 || shouldUpdatePrompts){
      fetchLatestPrompts();
      setShouldUpdatePrompts(false); 
    }
  }, [latestPrompts, fetchLatestPrompts, setShouldUpdatePrompts]);

  useEffect(() => {
    if (open) {
      setPromptText('');
      setSavePrompt(false);
      dialogRef?.current?.showModal();
    } else {
      dialogRef?.current?.close();
    }
  }, [open]);

  return (
    <>
      <button onClick={togglePromptDialog}>
        <Terminal size={16} />
        Prompt selected
      </button>
      <dialog ref={dialogRef} className="prompt-multiple-dialog">
        <h1>Prompt selected with:</h1>
        <form>
          <div>
            <input
              size="50"
              type="text"
              value={promptText}
              onChange={(e) => {
                setPromptText(e.target.value);
                if (e.target.value.length < 3 && savePromptChecked) {
                  setSavePrompt(false);
                }
              }}
            />
          </div>
          <div>
            <label>
              <input
                type="checkbox"
                checked={savePromptChecked}
                onChange={(e) => setSavePrompt(e.target.checked)}
                disabled={promptText.length < 3}
              />
              Save Prompt
            </label>
          </div>
          <div className={styles.latestPrompts}>
            <h2 className={styles.latestPromptsTitle}>Latest Prompts</h2>
            {loadingLatestPrompts ? (
              <p>Loading...</p>
            ) : (
              <ul>
                {latestPrompts?.map((prompt) => (
                  <li key={prompt?._id} className={styles.latestPromptsList} onClick={() => setPromptText(prompt?.promptText)}>{prompt?.promptText}</li>
                ))}
              </ul>
            )}
          </div>
          <div>
            <button
              type="submit"
              disabled={!promptText}
              onClick={promptMultiple}
            >
              Submit
            </button>
            <button type="button" onClick={closeDialog}>
              Cancel
            </button>
          </div>
        </form>
      </dialog>
    </>
  );
};
export default PromptDialog;

import React from 'react';
import { useBubblesActions } from '../../store/hooks/use-bubbles';
import Breadcrumb from './breadcrumb/breadcrumb';
import NameEditor from './canvas/name-editor';
import styles from './main-view.module.sass';

const Title = ({ bubble }) => {
  const { updateBubble } = useBubblesActions();

  const handleNameChange = (name) => {
    updateBubble({ id: bubble.id, properties: { name } });
  };

  return (
    <div className={`${styles.headerName} header-name`}>
      <Breadcrumb />

      <NameEditor
        bubbleId={bubble.id}
        initialName={bubble.name}
        onNameChange={handleNameChange}
      />
    </div>
  );
};

export default Title;

import schema from './document-schema';
import { v4 as uuidv4 } from 'uuid';

const buildDocument = ({ documentChildren, bubbles }) => {
  // go through document children and their children
  // parse the tree and add them as nodes accordingly

  const emptyNode = schema.node('paragraph', null, []);

  const nodes = documentChildren?.length
    ? documentChildren.map((bubbleId) => {
        const bubble = bubbles.find((b) => b.id === bubbleId);
        // content

        const node = bubble.name
          ? schema.nodeFromJSON(JSON.parse(bubble.name))
          : schema.node('paragraph', null, []);

        if (node.type.name === 'list_item') {
          // we must wrap this in a list
          const wrapperNode = schema.node('bullet_list', null, [node]);
          return wrapperNode;
        }

        return node;
      })
    : [emptyNode];

  return schema.node('doc', null, nodes);
};

export const getDocumentTextContent = ({ documentChildren, bubbles }) => {
  const doc = buildDocument({ documentChildren, bubbles });
  return doc.textContent;
};

export const getNodeFromPlainText = (plainText) => {
  const node = schema.node('paragraph', { id: uuidv4() }, [
    schema.text(plainText),
  ]);

  return node;
};

export default {
  getDocumentTextContent,
  getNodeFromPlainText,
};

import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { createBrowserHistory } from 'history';
import { createReduxHistoryContext } from 'redux-first-history';
import appReducer from './app.slice';
import bubblesReducer from './bubbles.slice';

const persistConfig = {
  key: 'bubbles',
  storage,
};

const { createReduxHistory, routerMiddleware, routerReducer } =
  createReduxHistoryContext({
    history: createBrowserHistory(),
  });

const reducers = combineReducers({
  app: appReducer,
  bubbleData: bubblesReducer,
  router: routerReducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(routerMiddleware),
});

export const history = createReduxHistory(store);

export default store;

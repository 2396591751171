import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import {
  useNodeViews,
  react,
  useEditorEffect,
  ProseMirror,
  useEditorEventCallback
} from '@nytimes/react-prosemirror';
import { undo, redo, history } from 'prosemirror-history';
import { EditorState, Plugin } from 'prosemirror-state';
import { DOMParser, Slice, Fragment } from 'prosemirror-model';
import { dropCursor } from 'prosemirror-dropcursor';
import { keymap } from 'prosemirror-keymap';
import { exampleSetup } from 'prosemirror-example-setup';

import 'prosemirror-view/style/prosemirror.css';

import DraggableDocumentBubble from './document-bubble';
import { createIdPlugin } from './create-id-plugin';

import schema from './document-schema';

import keys from './keys';

import styles from './prosemirror-editor.module.sass';

import { buildInputRules } from './input-rules';
import ErrorBoundary from '../../error-boundary';

export const ClickPadder = () => {
  const ClickFocusPlugin = useEditorEventCallback((view) => {
    view?.focus();
  });
  return(
    <div className={styles.editorFiller} onClick={ClickFocusPlugin} />
  );
}

const PastePlugin = (editor) => {
  return new Plugin({
    props: {
      /*     clipboardTextParser: function(str, $context) {
				if (/^<iframe\s.*>.*<\/iframe>$/igm.test(str) == false) return;
				var doc = document.cloneNode(false);
				var dom = doc.createElement('div');
				dom.innerHTML = str;
				var parser = editor.someProp("clipboardParser") || editor.someProp("domParser") || Model.DOMParser.fromSchema(editor.state.schema);
				return parser.parseSlice(dom, {preserveWhitespace: true, context: $context});
			},*/
      transformPasted: function (pslice) {
        let pastedText = '';
        pslice.content.forEach((node) => {
          pastedText += node.textContent;
        });
        try {
          const parsed = JSON.parse(pastedText);
          if (parsed.type === 'copied-bubbles') {
            return null;
          }
        } catch {}

        const nodes = [];
        pslice.content.forEach((node) => {
          node.attrs.id = uuidv4();
          nodes.push(node);
        });
        const frag = Fragment.fromArray(nodes);
        return new Slice(frag, pslice.openStart, pslice.openEnd);
      },
    },
  });
};

// Make sure that your ReactNodeViews are defined outside of
// your component, or are properly memoized. ProseMirror will
// teardown and rebuild all NodeViews if the nodeView prop is
// updated, leading to unbounded recursion if this object doesn't
// have a stable reference.
const reactNodeViews = {
  block: () => ({
    component: DraggableDocumentBubble,
    // We render the Paragraph component itself into a div element
    dom: document.createElement('div'),
    // We render the paragraph node's ProseMirror contents into
    // a span, which will be passed as children to the Paragraph
    // component.
    contentDOM: document.createElement('span'),
  }),
  paragraph: () => ({
    component: DraggableDocumentBubble,
    // We render the Paragraph component itself into a div element
    dom: document.createElement('div'),
    // We render the paragraph node's ProseMirror contents into
    // a span, which will be passed as children to the Paragraph
    // component.
    contentDOM: document.createElement('span'),
  }),
  list_item: () => ({
    component: DraggableDocumentBubble,
    // We render the Paragraph component itself into a div element
    dom: document.createElement('div'),
    // We render the paragraph node's ProseMirror contents into
    // a span, which will be passed as children to the Paragraph
    // component.
    contentDOM: document.createElement('span'),
  }),
  bullet_list: () => ({
    component: DraggableDocumentBubble,
    // We render the Paragraph component itself into a div element
    dom: document.createElement('div'),
    // We render the paragraph node's ProseMirror contents into
    // a span, which will be passed as children to the Paragraph
    // component.
    contentDOM: document.createElement('span'),
  }),
  ordered_list: () => ({
    component: DraggableDocumentBubble,
    // We render the Paragraph component itself into a div element
    dom: document.createElement('div'),
    // We render the paragraph node's ProseMirror contents into
    // a span, which will be passed as children to the Paragraph
    // component.
    contentDOM: document.createElement('span'),
  }),
  heading: () => ({
    component: DraggableDocumentBubble,
    // We render the Paragraph component itself into a div element
    dom: document.createElement('div'),
    // We render the paragraph node's ProseMirror contents into
    // a span, which will be passed as children to the Paragraph
    // component.
    contentDOM: document.createElement('span'),
  }),
};

const AutoFocusPlugin = () => {
  const [autofocused, setAutofocused] = useState(false);

  useEditorEffect((view) => {
    if (!view || autofocused) return;
    view?.focus();
    setAutofocused(true);
  });
};



export function ProseMirrorEditor({ initialDoc, onChange }) {
  const { nodeViews, renderNodeViews } = useNodeViews(reactNodeViews);
  // It's important that mount is stored as state,
  // rather than a ref, so that the ProseMirror component
  // is re-rendered when it's set

  const plugins = [
    createIdPlugin(),
    buildInputRules(schema),
    dropCursor(),
    // ...exampleSetup({ schema, menuBar: false }),
    keys,
    react(),
    PastePlugin(),
    history(),
    keymap({ 'Mod-z': undo, 'Mod-y': redo }),
  ];

  const [mount, setMount] = useState(null);

  const emptyEditorState = EditorState.create({
    doc: DOMParser.fromSchema(schema).parse('<p></p>'),
    plugins,
  });

  const [editorState, setEditorState] = useState(
    initialDoc
      ? EditorState.create({
          doc: initialDoc,
          plugins,
        })
      : emptyEditorState,
  );

  useEffect(() => {
    if (initialDoc) {
      setEditorState(
        EditorState.create({
          doc: initialDoc,
          plugins,
        }),
      );
    }
  }, [initialDoc]);

  useEffect(() => {
    onChange(editorState);
  }, [editorState]);

  return (
    <div className={styles.prosemirrorContainer}>
      <ErrorBoundary onError={() => setEditorState(emptyEditorState)}>
        <ProseMirror
          mount={mount}
          state={editorState}
          dispatchTransaction={(tr) => {
            setEditorState((s) => {
              const newState = s.apply(tr);
              // console.log(newState);
              // onChange(newState);
              return newState;
            });
          }}
          nodeViews={nodeViews}
        >
          <AutoFocusPlugin />
          <div ref={setMount} />
          {renderNodeViews()}
          <ClickPadder />
        </ProseMirror>
      </ErrorBoundary>

    </div>
  );
}

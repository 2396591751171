import React, { useState, useRef, useEffect } from 'react';
import { SquarePlus, Minimize2, CopyPlus, CircleMinus, FilePlus, Sparkle } from "lucide-react";

import { BASE_URL } from '../../config';

import styles from './header.module.sass';

import PromptDialog from './prompt-dialog';

import { useAppState, useAppActions } from '../../store/hooks/use-app';
import { useBubblesActions } from '../../store/hooks/use-bubbles';

import { searchBubbles } from '../../api';

const parseName = (name) => {
  try {
    return JSON.parse(name).content.map((content) => content.text);
  } catch {
    return [name];
  }
};

const SearchResult = ({ result, setSearchResults, setSearchQuery }) => {
  const parsed = parseName(result.name);

  const { loadBubbles, syncBubbles } = useBubblesActions();

  const loadSearchResult = async () => {
    syncBubbles();
    loadBubbles({ newFocusedBubbleId: result.id });
    // setSearchResults([]);
    // setSearchQuery(parseName(result.name));
  };

  return (
    <div className={styles.searchResult} onClick={loadSearchResult}>
      {parsed.map((content, index) => (
        <p key={`${content}-${index}`}>{content}</p>
      ))}
    </div>
  );
};

const Header = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [profileSettings, setProfileSettings] = useState(false);
  const searchContainerRef = useRef(null);

  const handleInput = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSearchKeyDown = (event) => {
    if (event.key === 'Enter') {
      search();
    }
  };

  const search = async () => {
    setSearchResults(['Loading...']);

    try {
      const data = await searchBubbles({ searchTerm: searchQuery });
      if (data.success) {
        if (data.results.length > 0) {
          setSearchResults(data.results); // Update the state with the search results
        } else {
          setSearchResults(['No data found']);
        }
      } else {
        setSearchResults(['No data found']);
      }
    } catch (error) {
      console.error('Search error:', error);
      setSearchResults(['No data found']);
    }
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        searchContainerRef.current &&
        !searchContainerRef.current.contains(event.target)
      ) {
        // Clicked outside the search container
        setSearchResults([]); // Clear search results or hide the div as needed
      }
    }

    // Add event listener when the component mounts
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Remove event listener on cleanup
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [searchContainerRef]); // Empty array ensures this effect runs only on mount and unmount

  const { loggedIn, user, darkMode } = useAppState();
  const { setDarkMode } = useAppActions();
  const {
    signOutUser,
    newCanvas,
    newDocument,
    duplicateSelectedBubbles,
    removeSelectedCanvasBubbles,
  } = useBubblesActions();

  const { stepOut } = useBubblesActions();

  return (
    <header>
      <div className={styles.staticMenu}>
        {/* <button>
          <Sparkle size={16} />
          Start from a template
        </button> */}
        <button onClick={newCanvas}>
          <SquarePlus size={16} />
          New canvas
        </button>
        <button onClick={newDocument}>
          <FilePlus size={16} />
          New document
        </button>
        <PromptDialog />
      </div>
      {/* <div className={styles.dynamicMenu}>
        <button className={styles.stepOut} onClick={stepOut}>
          <Minimize2 size={16} />
          Context view
        </button>
        <button onClick={duplicateSelectedBubbles}>
          <CopyPlus size={16} />
          Duplicate
        </button>
        {/* <button onClick={removeSelectedCanvasBubbles}>
          <CircleMinus size={16} />
          Remove
        </button> */}
        {/* <button>Save view as a bubble</button> */}
        {/* <button>✨ Show related</button>
      </div> */}
      <div className={styles.search} ref={searchContainerRef}>
        <input
          type="text"
          placeholder="Search"
          onKeyDown={handleSearchKeyDown}
          onChange={handleInput}
          value={searchQuery}
        />
        {/* Search results container */}

        <div
          className={`${styles.searchResults} ${
            searchResults.length === 0 ? styles.empty : ''
          }`}
        >
          {(searchResults.length === 1 &&
            searchResults[0] === 'Loading...') ||
          searchResults[0] === 'No data found'
            ? searchResults.map((content, index) => (
                <p style={{ marginLeft: '10px' }} key={content}>
                  {content}
                </p>
              ))
            : // Pass setSearchResults to SearchResult
              searchResults.map((result) => (
                <SearchResult
                  result={result}
                  key={result._id}
                  setSearchResults={setSearchResults}
                  setSearchQuery={setSearchQuery}
                />
              ))}
        </div>
      </div>
      <div className={styles.searchAndProfile}>

        {/* <button onClick={() => setDarkMode(!darkMode)}>
          {darkMode ? '☀️' : '🌙'}
        </button> */}
        {loggedIn ? (
          <>
            <div className={styles.profileName} onClick={() => setProfileSettings(!profileSettings)}>{user?.name?.charAt?.(0)}
            {/* if (profileSettings) { */}
            {profileSettings &&
              <ul className={styles.profileSettings}> 
                <li>Settings</li>
                <li onClick={signOutUser}>
                  Sign out
                </li>
              </ul>
            }
            </div>
          </>
        ) : (
          <div className={styles.sign}>
            <a href={`${BASE_URL}/auth/login/federated/google`}>Sign in</a>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;

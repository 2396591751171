import React from 'react';
import styles from '../main-view.module.sass';

const BreadcrumbDropdownMenu = ({ items, onSelect, getBubbleContent }) => {
  return (
    <div className={styles.breadcrumbDropdownMenu}>
      {items.map((item, index) => (
        <div
          key={index}
          className={styles.breadcrumbDropdownMenuItem}
          onClick={() => onSelect(item)}
        >
          {getBubbleContent(item) ?? ''}
        </div>
      ))}
    </div>
  );
};

export default BreadcrumbDropdownMenu;

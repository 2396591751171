import { Schema } from 'prosemirror-model';
import { schema, nodes } from 'prosemirror-schema-basic';
import { addListNodes } from 'prosemirror-schema-list';

const baseNodes = {
  ...nodes,
  paragraph: {
    content: 'inline*',
    group: 'block',
    attrs: {
      id: { default: null },
    },
    parseDOM: [
      {
        tag: 'p',
        getAttrs: (dom) => ({ id: dom.getAttribute('data-id') }),
      },
    ],
    toDOM(node) {
      const { id } = node.attrs;
      return ['p', { 'data-id': id }, 0];
    },
  },
  heading: {
    content: 'inline*',
    group: 'block',
    attrs: {
      id: { default: null },
    },
    parseDOM: [
      {
        tag: 'h1',
        attrs: { level: 1, id: { default: null } },
        getAttrs: (dom) => ({ id: dom.getAttribute('data-id') }),
      },
      {
        tag: 'h2',
        attrs: { level: 2, id: { default: null } },
        getAttrs: (dom) => ({ id: dom.getAttribute('data-id') }),
      },
      {
        tag: 'h3',
        attrs: { level: 3, id: { default: null } },

        getAttrs: (dom) => ({ id: dom.getAttribute('data-id') }),
      },
      {
        tag: 'h4',
        attrs: { level: 4, id: { default: null } },

        getAttrs: (dom) => ({ id: dom.getAttribute('data-id') }),
      },
      {
        tag: 'h5',
        attrs: { level: 5, id: { default: null } },

        getAttrs: (dom) => ({ id: dom.getAttribute('data-id') }),
      },
      {
        tag: 'h6',
        attrs: { level: 6, id: { default: null } },
        getAttrs: (dom) => ({ id: dom.getAttribute('data-id') }),
      },
    ],
    toDOM(node) {
      const { id } = node.attrs;
      return ['h1', { 'data-id': id }, 0];
    },
  },
  doc: { content: 'block+' },
};

const baseSchema = new Schema({
  nodes: baseNodes,
  marks: schema.spec.marks,
});

const mySchema = new Schema({
  nodes: addListNodes(baseSchema.spec.nodes, 'paragraph block*', 'block'),
  marks: baseSchema.spec.marks,
});

mySchema.nodes.bullet_list.attrs = {
  id: { default: null },
};

mySchema.nodes.ordered_list.attrs = {
  id: { default: null },
};

mySchema.nodes.list_item.attrs = {
  id: { default: null },
};

export default mySchema;

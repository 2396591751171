import React, { forwardRef, useEffect } from 'react';

import { useDraggable } from '@dnd-kit/core';

import styles from './document-bubble.module.sass';

import { Transform } from 'prosemirror-transform';

import { DOCUMENT_BUBBLE } from '../../../types';
import {
  useEditorEventCallback,
  useEditorEventListener,
} from '@nytimes/react-prosemirror';

import classnames from 'classnames';
import { useBubblesActions } from '../../../store/hooks/use-bubbles';

const DEBUG = false;

export const DocumentBubble = forwardRef(
  ({ children, isCommand, type, id, ...props }, ref) => {
    return (
      <div
        className={classnames(styles.documentBubble, {
          [styles.command]: isCommand,
          command: isCommand,
        })}
        ref={ref}
      >
        <div className={styles.dragHandle} {...props} tabIndex={-1} />
        <div className={styles.content}>
          {DEBUG ? (
            <div contentEditable={false}>
              <div>
                {type} ({id ? id.slice(0, 20) : ' no id '})
              </div>
            </div>
          ) : null}
          {type === 'paragraph' && <p>{children}</p>}
          {type === 'bullet_list' && <ul>{children}</ul>}
          {type === 'ordered_list' && <ol>{children}</ol>}
          {type === 'list_item' && <li>{children}</li>}
        </div>
      </div>
    );
  },
);

DocumentBubble.displayName = 'DocumentBubble';

export const DraggableDocumentBubble = ({ node, view, children, ...props }) => {
  const {
    attrs: { id },
  } = node;

  const { handleImageGeneration, handleTextGeneration } = useBubblesActions();

  // TODO: this is the source of editorstate not updating bug in chrome, fix it here eventually
  useEditorEventListener('keydown', (_, event) => {
    if (!((event.ctrlKey || event.metaKey) && event.code === 'Enter')) {
      return false;
    }

    try {
      const transformer = new Transform(node);
      const content = transformer.doc.textContent.toString();

      const [command] = content?.split(' ');
      const promptText = content?.substring(content.indexOf(' ') + 1);
      switch (command) {
        case '/image':
          console.log('handling image generation for prompt', promptText);
          handleImageGeneration({ promptText });
          break;
        case '/ask':
          console.log('handling text generation for prompt', promptText);
          handleTextGeneration({ promptText });
          break;
        default:
          console.error('Unknown command:', command);
      }
      return true;
    } catch (e) {
      console.error(e);
    }
    return false;
  });

  const transformer = new Transform(node);
  const content = transformer.doc.textContent.toString();
  const isCommand = content.match('(/ask|/image)');

  // remove node from editor
  const onRemove = useEditorEventCallback((view) => {
    if (!view) return;

    const { state } = view;
    state.doc.descendants((node, pos) => {
      if (node.attrs?.id === id) {
        view.dispatch(state.tr.delete(pos, pos + node.nodeSize));
      }
    });
  });

  const { isDragging, attributes, listeners, setNodeRef } = useDraggable({
    id,
    data: {
      id,
      type: DOCUMENT_BUBBLE,
      bubble: { id },
      onRemove,
    },
  });

  if (isDragging) {
    return null;
  }

  return (
    <DocumentBubble
      ref={setNodeRef}
      {...listeners}
      {...attributes}
      isCommand={isCommand}
      type={node.type.name}
      id={id}
    >
      {children}
    </DocumentBubble>
  );
};

export default DraggableDocumentBubble;

import { Plugin } from 'prosemirror-state';
import { v4 as uuidv4 } from 'uuid';

const attrName = 'id';

const isTargetNodeOfType = (node, types) =>
  types.some((type) => node.type === type);

const isNodeHasUniqueAttribute = (node, pos, doc, attrName) => {
  const hasAttribute = Boolean(node.attrs?.[attrName]);
  const attr = node.attrs[attrName];
  let isUnique = true;
  doc.descendants((n, p) => {
    // check if anything before our position has the same ID
    if (n.attrs?.[attrName] === attr && p < pos) {
      isUnique = false;
    }
  });
  return hasAttribute && isUnique;
};

export const createIdPlugin = () => {
  return new Plugin({
    appendTransaction: (transactions, _prevState, nextState) => {
      const tr = nextState.tr;
      let modified = false;
      if (transactions.some((transaction) => transaction.docChanged)) {
        // Adds a unique id to a node
        nextState.doc.descendants((node, pos) => {
          /*const { paragraph, heading, ordered_list, bullet_list, list_item } =
            nextState.schema.nodes;
          const nodes = [
            paragraph,
            heading,
            ordered_list,
            bullet_list,
            list_item,
          ];*/
          const nodes = [nextState.schema.nodes.text];
          if (
            !isTargetNodeOfType(node, nodes) &&
            !isNodeHasUniqueAttribute(node, pos, nextState.doc, attrName)
          ) {
            const attrs = node.attrs;
            const id = `create-id-plugin-${uuidv4()}`;
            console.log(node.attrs);
            console.log(
              `Now setting new ID ${id} at ${JSON.stringify(
                node.type.name,
              )} at ${pos}`,
            );
            tr.setNodeMarkup(pos, null, {
              ...attrs,
              [attrName]: id,
            });
            modified = true;
          }
        });
      }

      return modified ? tr : null;
    },
  });
};

import { toggleMark } from 'prosemirror-commands';
import {
  chainCommands,
  baseKeymap,
  newlineInCode,
  createParagraphNear,
  liftEmptyBlock,
  splitBlock,
} from 'prosemirror-commands';
import { keymap } from 'prosemirror-keymap';
import schema from './document-schema';

import { splitListItem, sinkListItem, liftListItem } from 'prosemirror-schema-list';

export default keymap({
  ...baseKeymap,
  'Mod-b': toggleMark(schema.marks.strong),
  'Mod-i': toggleMark(schema.marks.em),
  Enter: chainCommands(
    splitListItem(schema.nodes.list_item),
    newlineInCode,
    createParagraphNear,
    liftEmptyBlock,
    splitBlock,
  ),
  Tab: sinkListItem(schema.nodes.list_item),
  'Shift-Tab': liftListItem(schema.nodes.list_item),
});

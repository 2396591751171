import {
  inputRules,
  wrappingInputRule,
  /*textblockTypeInputRule,
  smartQuotes,
  emDash,
  ellipsis,*/
} from 'prosemirror-inputrules';

import { v4 as uuidv4 } from 'uuid';

/// Given a list node type, returns an input rule that turns a number
/// followed by a dot at the start of a textblock into an ordered list.
export function orderedListRule(nodeType) {
  return wrappingInputRule(
    /^(\d+)\.\s$/,
    nodeType,
    (match) => ({ order: +match[1], id: uuidv4() }),
    (match, node) => node.childCount + node.attrs.order == +match[1],
  );
}

/// Given a list node type, returns an input rule that turns a bullet
/// (dash, plush, or asterisk) at the start of a textblock into a
/// bullet list.
export function bulletListRule(nodeType) {
  return wrappingInputRule(/^\s*([-+*])\s$/, nodeType);
}

export function buildInputRules(schema) {
  let rules = [];
  let type;

  // if ((type = schema.nodes.blockquote)) rules.push(blockQuoteRule(type));
  if ((type = schema.nodes.ordered_list)) rules.push(orderedListRule(type));
  if ((type = schema.nodes.bullet_list)) rules.push(bulletListRule(type));
  // if ((type = schema.nodes.code_block)) rules.push(codeBlockRule(type));
  // if ((type = schema.nodes.heading)) rules.push(headingRule(type, 6));
  return inputRules({ rules });
}
